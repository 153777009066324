<template>

  <v-list-item
    class="vertical-nav-menu-link"
    v-bind="$attrs"
    active-class="bg-gradient-primary white--text"
    :key="$vuetify.theme.dark"
    @click="toggleDarkness"
  >
    <v-list-item-icon>
      <v-fade-transition mode="out-in">
        <v-icon
          class="mx-auto"
        >
          {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
        </v-icon>
      </v-fade-transition>
    </v-list-item-icon>

    <v-list-item-title>
      {{ $t('message.tooltips.theme-switcher') }}
    </v-list-item-title>
  </v-list-item>

</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  methods: {
    toggleDarkness() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  }
}
</script>

<style>
</style>
