import { Auth } from 'aws-amplify'

function requireAuth(to, from, next) {
  // Uncomment to disable authentication
  // next();

  Auth.currentAuthenticatedUser()
    .then(r => Auth.currentAuthenticatedUser()
      .then(u => {
        const { roles } = to.meta
        if (roles) {
          //console.log(r)

          // TODO: Consider another way to cache and maintain user groups.
          const groups = u.signInUserSession.accessToken.payload['cognito:groups']

          if (groups && groups.some(g => roles.includes(g))) {
            next()
          } else {
            next({ path: '/' })
          }
        } else {
          next()
        }
      })
      .catch(e => {
        //console.log(e)
      })
    )
    .catch(e => {
      //console.log(e)
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    })
}

export default requireAuth
