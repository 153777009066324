import { render, staticRenderFns } from "./NavMenuSectionTitle.vue?vue&type=template&id=b88333f0&scoped=true"
import script from "./NavMenuSectionTitle.vue?vue&type=script&lang=js"
export * from "./NavMenuSectionTitle.vue?vue&type=script&lang=js"
import style0 from "./NavMenuSectionTitle.vue?vue&type=style&index=0&id=b88333f0&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b88333f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VSubheader})
