<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <v-snackbar
      v-model="snackbar.active"
      class="pa-2 no-print"
      :color="snackbar.color"
      :bottom="true"
      :timeout="snackbar.timer"
    >
      {{ snackbar.message }}
      <template #action="{ attrs }">
        <v-btn color="white" text outlined v-bind="attrs" @click="snackbar.active = false">
          {{ $t('message.buttons.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import { mapState } from 'vuex'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },

  async mounted() {
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedOut') {
        this.$router.push('/login')
      } else {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push('/teams')
        }
      }
    })
  },

  computed: {
    ...mapState(['snackbar']),
  },

  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>

<style lang="sass">
$desktop: 1024px
$tablet: 480px

=desktop
  @media screen and (min-width: $desktop)
    @content

=tablet
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px)
    @content

=mobile
  @media screen and (max-width: $tablet - 1px)
    @content

:root
  --amazonOrange: #c42127
  --button-click: #828282

div
  &[class*="Form__formSection"]
    +mobile
      min-width: auto !important

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

  &.theme--light
    background: radial-gradient(#ffffff, #f0f0f0)
    .v-label
      color: rgba(96,125,140,0.9)
    .v-data-table
      table
        thead
          tr
            th
              border-bottom: 1px solid #c42127

  &.theme--dark
    background: radial-gradient(#0f0f0f, #000000)
    .v-label
      color: rgba(96,125,140,0.9)
    .v-data-table
      table
        thead
          tr
            th
              border-bottom: 1px solid #c42127

html
  overflow-y: auto

.align-center
  text-align: baseline

.word-break
  word-break: break-word !important

.save-hob
  margin-bottom: 45px
  z-index: 2

@media print
  .no-print,
  .no-print *
    display: none !important
    width: 0

  .printable
    padding: 0 !important

.v-toolbar
  flex: none !important

.sticky-top-50
  position: sticky
  top: 48px
  z-index: 2
  background: inherit
  border-bottom: 1px solid red

.v-expansion-panel
  .v-expansion-panel-content__wrap
    padding: 0 !important


.v-data-table > .v-data-table__wrapper > table > tbody > tr > td
  font-size: 12px !important
  height: 1.4rem !important

</style>
