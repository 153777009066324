import { Auth } from 'aws-amplify'

async function getSessionAccessToken() {
  const session = await Auth.currentSession()
  const { jwtToken } = session.accessToken

  return jwtToken
}

export default getSessionAccessToken
