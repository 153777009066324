var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.isLoaded)?_c('div',[_c('vertical-nav-menu',{staticClass:"no-print",attrs:{"is-drawer-open":_vm.isDrawerOpen,"resources":Object.keys(this.$store.state.resources)},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen=$event}}}),_c('v-app-bar',{staticClass:"no-print",attrs:{"top":"","app":"","raised":"","dense":""}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"d-flex align-center mx-5"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticClass:"d-block d-md-none me-2",on:{"click":function($event){_vm.isDrawerOpen = !_vm.isDrawerOpen}}},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,361448213)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.tooltips.menu')))])]),(_vm.$store.getters.siteType)?_c('v-alert',{staticClass:"text-uppercase",attrs:{"width":"2000px","type":_vm.$store.getters.siteType == 'DEVONLY' ? 'error' : 'warning'}},[_vm._v(_vm._s(_vm.$store.getters.siteType))]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ms-3",attrs:{"icon":"","small":"","to":{ name: 'help' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiHelpCircleOutline)+" ")])],1)]}}],null,false,2737035134)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.tooltips.help')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ms-3",attrs:{"icon":"","small":"","to":{ name: 'transactions' }}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.alerts,"content":_vm.alertsPlus,"color":"primary","overlap":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiBellOutline)+" ")])],1)],1)]}}],null,false,475877672)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.tooltips.alerts')))])]),_c('app-bar-user-menu')],1)])]),_c('v-main',{staticClass:"printable mb-15"},[_c('div',{staticClass:"app-content-container"},[_vm._t("default")],2)]),_c('v-fade-transition',[(_vm.scrolledDown)?_c('v-btn',{staticClass:"hob no-print",attrs:{"color":"secondary","elevation":"12","fab":""},on:{"click":_vm.gotoTop}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronUp))])],1):_vm._e()],1),_c('v-footer',{staticClass:"px-0 no-print",attrs:{"app":"","inset":"","color":"transparent","absolute":"","height":"56"}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"mx-6 d-flex justify-space-between"},[_c('span',[_vm._v(" © "+_vm._s(_vm._f("moment")(Date.now(),'YYYY'))+" "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://www.cfl.ca","target":"_blank"}},[_vm._v("CFL")])]),_c('div',{on:{"click":_vm.toggle}},[(_vm.toggler)?_c('v-img',{attrs:{"width":"32px","alt":"football-player","src":require("@/assets/images/FOOTBALL-GUY.png")}}):_vm._e(),(!_vm.toggler)?_c('v-img',{attrs:{"width":"32px","alt":"football-player","src":require("@/assets/images/FOOTBALL-GUY_B.png")}}):_vm._e()],1),_c('span',{staticClass:"d-sm-inline"})])])])],1):_c('div',{staticClass:"full-page"},[_c('v-progress-circular',{staticClass:"mid-page",attrs:{"size":50,"width":5,"color":"primary","indeterminate":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }