import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import MESSAGES from '@/locale/index'
import VueSanitize from "vue-sanitize"


Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  },
});

const i18n = {
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: MESSAGES // set locale messages
}

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(VueI18n)
Vue.use(require('vue-moment'));
Vue.use(VueSanitize);

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
