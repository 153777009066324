import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: { active: false, color: "", message: "", timer: 750 },
    resources: {},
    packageVersion: process.env.VUE_APP_VERSION,
    siteType: process.env.VUE_APP_TYPE,
    transactionsCount: 0,
    requestsCount: 0
  },
  mutations: {
    updateSnackbar: (state, snackbar) => {
      state.snackbar = snackbar
    },
    updateResources: (state, payload) => {
      state.resources = payload
    },
    updateTransactionCount: (state, payload) => {
      state.transactionsCount = payload
    },
    updateRequestsCount: (state, payload) => {
      state.requestsCount = payload
    }
  },
  getters: {
    resourceHas: (state) => (resource, method) => {
      return state.resources.hasOwnProperty(resource)&&state.resources[resource].some(item => item === method)
    },
    appVersion: (state) => {
      return state.packageVersion
    },
    siteType: (state) => {
      return state.siteType
    },
    transactionsCount: (state => {
      return state.transactionsCount
    }),
    requestsCount: (state => {
      return state.requestsCount
    })
  },
  actions: {
    showSnack({ commit }, { message, color, timer }) {
      commit("updateSnackbar", {
        active: true,
        color: color,
        message: message,
        timer: timer
      });
    },
    updateTransactionCount({ commit }, { count }) {
      commit("updateTransactionCount", count)
    },
    updateRequestsCount({ commit }, { count }) {
      commit("updateRequestsCount", count)
    }
  },
  modules: {},
})
