<template>
  <v-tooltip right>
    <template v-slot:activator="{ on }">
       
      <v-list-item
        v-on="on"
        class="pl-6 vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
        v-if="enabled"
      >
        <v-list-item-icon>
          
          <v-icon
            :class="{'alternate-icon-small': !icon}"
            class="mr-5"
          >
            {{ icon || alternateIcon }}
          </v-icon>
          
        </v-list-item-icon>
        
        <v-list-item-title class="text-left">
          {{ title }} 
          <v-badge
            class="ml-2"
            :value="badge"
            :content="badge > 99 ? '99+' : badge"
            color="primary"
          ></v-badge>
        </v-list-item-title>
        

        
      </v-list-item>

    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    enabled: {
      type: Boolean,
      default: false
    },
    badge: {
      type: Number,
      default: null
    }
  },
  setup() {
    return {
      alternateIcon: mdiCheckboxBlankCircleOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(194, 50, 50, 0.5);
    @include elevationTransition();
  }
}
</style>
