<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{...tooltip, ...menu}"
            icon
            small
            class="ms-3"
          >
            <v-icon>{{ icons.mdiAccountOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('message.tooltips.account') }}</span>
      </v-tooltip>
    </template>
    <v-list>

      <!-- Profile -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><strong>{{ $t('message.profile.username') }}:</strong> {{ userInfo.username }}</v-list-item-title>
          <v-list-item-title><strong>{{ $t('message.profile.group') }}:</strong> {{ getUserGroup }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>
      
      <locale-switcher></locale-switcher>
      <theme-switcher></theme-switcher>

      <v-divider class="my-2"></v-divider>


      <!-- Logout -->
      <amplify-sign-out></amplify-sign-out>

    </v-list>
  </v-menu>
</template>

<script>

import ThemeSwitcher from './ThemeSwitcher.vue'
import LocaleSwitcher from './LocaleSwitcher.vue'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { Auth } from 'aws-amplify'

export default {
  components: {
    ThemeSwitcher,
    LocaleSwitcher
  },
  data: () => ({
    userInfo: {
      attributes: ''
    }
  }),
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  async mounted() {
    this.userInfo = await Auth.currentAuthenticatedUser()   
  },
  computed: {
    getUserGroup() {
      if (!this.userInfo || !this.userInfo.signInUserSession) { return }
      return this.userInfo.signInUserSession.accessToken.payload['cognito:groups'] ? this.userInfo.signInUserSession.accessToken.payload['cognito:groups'] : ''
    }
  }
}
</script>

<style lang="sass">
.user-profile-menu-content
  .v-list-item
    min-height: 2.5rem !important
 
.Form__formField___2DWhT
  margin-bottom: 0
</style>
