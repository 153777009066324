<template>
  <v-app>
    <div v-if="isLoaded">
      <vertical-nav-menu class="no-print" :is-drawer-open.sync="isDrawerOpen" :resources="Object.keys(this.$store.state.resources)"></vertical-nav-menu>
      
      <v-app-bar
        top
        app
        raised
        dense
        class="no-print"
      >
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-5">

            <!-- Left Content -->
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon
                  v-bind="attrs"
                  v-on="on"
                  class="d-block d-md-none me-2"
                  @click="isDrawerOpen = !isDrawerOpen"
                ></v-app-bar-nav-icon>
              </template>
              <span>{{ $t('message.tooltips.menu') }}</span>
            </v-tooltip>

              <v-alert
                v-if="$store.getters.siteType"
                width="2000px"
                class="text-uppercase"
                :type="$store.getters.siteType == 'DEVONLY' ? 'error' : 'warning'"
              >{{ $store.getters.siteType }}</v-alert>

            <v-spacer></v-spacer>

            <!-- Right Content -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="ms-3"
                  :to="{ name: 'help' }"
                >
                  <v-icon>
                    {{ icons.mdiHelpCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('message.tooltips.help') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="ms-3"
                  :to="{ name: 'transactions' }"
                >
                  <v-badge
                    :value="alerts"
                    :content="alertsPlus"
                    color="primary"
                    overlap
                  >
                    <v-icon>
                      {{ icons.mdiBellOutline }}
                    </v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>{{ $t('message.tooltips.alerts') }}</span>
            </v-tooltip>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>

      <v-main class="printable mb-15">
        <div class="app-content-container">          
          <slot></slot>
        </div>
      </v-main>

      <v-fade-transition>
        <v-btn v-if="scrolledDown" class="hob no-print" color="secondary" elevation="12" fab @click="gotoTop"><v-icon>{{ icons.mdiChevronUp }}</v-icon></v-btn>
      </v-fade-transition>

      <v-footer
        app
        inset
        color="transparent"
        absolute
        height="56"
        class="px-0 no-print"
      >
        <div class="boxed-container w-full">
          <div class="mx-6 d-flex justify-space-between">
            <span>
              &copy; {{ Date.now() | moment('YYYY') }}
              <a
                href="https://www.cfl.ca"
                class="text-decoration-none"
                target="_blank"
              >CFL</a>
            </span>
            <div @click="toggle">
              <v-img width="32px" v-if="toggler" alt="football-player" src="@/assets/images/FOOTBALL-GUY.png"></v-img>
              <v-img width="32px" v-if="!toggler" alt="football-player" src="@/assets/images/FOOTBALL-GUY_B.png"></v-img>
            </div>


            <span class="d-sm-inline">
              
            </span>
          </div>
        </div>
      </v-footer>

    </div>
    <div class="full-page" v-else>
      <v-progress-circular
        class="mid-page"
        :size="50"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiChevronUp, mdiHelpCircleOutline} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import { TRANSACTIONS_SERVICE, PLAYER_REQUESTS_SERVICE } from '@/app/services'
import APIRequest from './../app/_api_request'
import { mapState } from 'vuex'

export default {
  data: () => ({
    isLoaded: false,
    scrolledDown: false,
    toggler: false,
    alerts: 0, //TODO: get transaction pending qty
    resources: {}
  }),
  
  components: {
    VerticalNavMenu,
    AppBarUserMenu,
  },

  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiChevronUp,
        mdiHelpCircleOutline
      },
    }
  },

  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('scroll', this.handleScroll)
  },

  beforeMount() {
    this.checkAccess()
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.init()
    this.checkPendingApprovals(false)
    setInterval(() => {
      this.checkPendingApprovals(true)
    }, 18000)
  },

  computed: {
    alertsPlus() {
      return this.alerts > 24 ? '24+' : this.alerts
    }
  },

  methods: {
    checkAccess() {
      APIRequest.getApiRequest('/api/permissions/current')
      .then(item => {
          this.$store.commit('updateResources', item.hasOwnProperty('Allowed Resources')?item["Allowed Resources"]:{})        
          this.isLoaded = true
        }
      )
      .catch(e => {
          this.$router.push('/server-unavailable')
          this.isLoaded = true
        }
      )
    },
    init() {
      const theme = localStorage.getItem("dark_theme");
        if (theme) {
            this.$vuetify.theme.dark = theme == "true";
        }
        const locale = localStorage.getItem("lang");
        if (locale) {
          this.$i18n.locale = locale
        }
    },
    handleScroll() {
      this.scrolledDown = window.scrollY > 200
    },
    toggle() {
      this.toggler = !this.toggler
    },
    checkPendingApprovals(sendMessage) {
      if (this.$store.getters.resourceHas('transactions', 'read')){
        TRANSACTIONS_SERVICE.checkAnyPendingTransactions()
          .then((data) => {
            if (data.length > this.alerts && sendMessage) {
              this.$store.dispatch('showSnack', {
                message: this.$t('message.snackbar.new_pending_approvals'),
                color: '',
              })
            }
            this.alerts = data.length
            this.$store.commit('updateTransactionCount', data.length)   
          })
          .catch((e) => {
          })
      }

      if (this.$store.getters.resourceHas('player_requests', 'read')){
        PLAYER_REQUESTS_SERVICE.findAll(1,'asc','ID','&state=0')
          .then((data) => {            
            this.$store.commit('updateRequestsCount', data.length) 
          })
          .catch((e) => {
          })
      }
    },
    gotoTop() {
      document.getElementById('app').scrollIntoView({behavior: 'smooth'})
    }
  }
}
</script>

<style lang="sass" scoped>
.v-app-bar ::v-deep
  .v-toolbar__content
    padding: 0

    .app-bar-search
      .v-input__slot
        padding-left: 18px


.boxed-container
  margin-left: auto
  margin-right: auto

.hob
  z-index: 200
  position: fixed
  bottom: 63px
  right: 40px
  padding: 5px

.full-page
  position: fixed
  height: 100vh
  width: 100vw

.mid-page
  top: 45vh

</style>
