import APIRequest from './_api_request'

export default class CommentApi {

  //generic api comments
  async findAllComments(context, id, page, limit = 1) {
    return await APIRequest.getApiRequest(`/api/${context}/${id}/comments?page=${page}&limit=${limit}`)
  }
  async postComment(context, id, data) {
    return await APIRequest.postApiRequest(`/api/${context}/${id}/comments`, data)
  }
  async deleteComment(context, id, comment_id) {
    return await APIRequest.deleteApiRequest(`/api/${context}/${id}/comments/${comment_id}`)
  }
  async updateComment(context, id, comment_id, data) {
    return await APIRequest.putApiRequest(`/api/${context}/${id}/comments/${comment_id}`, data)
  }

}