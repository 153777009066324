<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  mounted() {
    this.init()
  },
  methods: {

    init() {
        const theme = localStorage.getItem("dark_theme");
          if (theme) {
              this.$vuetify.theme.dark = theme == "true";
          }
          const locale = localStorage.getItem("lang");
          if (locale) {
            this.$i18n.locale = locale
          }
      }
  
  }
}
</script>

<style lang="sass">
</style>
