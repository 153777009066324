<template>

  <v-list-item
    class="vertical-nav-menu-link"
    v-bind="$attrs"
    active-class="bg-gradient-primary white--text"
    :key="$i18n.locale"
    @click="toggleLocale"
  >
    <v-list-item-icon>
      <v-fade-transition mode="out-in">
        <v-icon
          class="mx-auto"
        >
          {{ $i18n.locale == 'en' ? icons.mdiTranslateOff : icons.mdiTranslate }}
        </v-icon>
      </v-fade-transition>
    </v-list-item-icon>

    <v-list-item-title>
      {{ $t('message.tooltips.locale-switcher') }}
    </v-list-item-title>
  </v-list-item>

</template>

<script>
import { mdiTranslate, mdiTranslateOff } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiTranslate,
        mdiTranslateOff,
      },
    }
  },
  methods: {   
    toggleLocale() {
      if (this.$i18n.locale == 'en') 
        { this.$i18n.locale = 'fr' }
      else 
        { this.$i18n.locale = 'en' }
      localStorage.setItem("lang", this.$i18n.locale);
    }
  }
}
</script>

<style>
</style>
