import axios from 'axios'
import getSessionAccessToken from './_token'

const API_HOST = process.env.VUE_APP_API_HOST

async function getRequestConfig() {
  const jwtToken = await getSessionAccessToken()
  return { headers: { Authorization: `Bearer ${jwtToken}` } }
}

async function getApiRequest(relativePath) {
  const path = API_HOST + relativePath
  const config = await getRequestConfig()
  const response = await axios.get(path, config)

  return response.data
}


async function putApiRequest(relativePath, data) {
  const path = API_HOST + relativePath
  const config = await getRequestConfig()
  const response = await axios.put(path, data, config)

  return response.data
}

async function postApiRequest(relativePath, data) {
  const path = API_HOST + relativePath
  const config = await getRequestConfig()
  const response = await axios.post(path, data, config)

  return response.data
}

async function postRawApiRequest(absolutePath, data) {
  const path = absolutePath
  const response = await axios.post(path, data)

  return response.data
}

async function deleteApiRequest(relativePath) {
  const path = API_HOST + relativePath
  const config = await getRequestConfig()
  const response = await axios.delete(path, config)

  return response.data
}



export default {
  getApiRequest,
  putApiRequest,
  deleteApiRequest,
  postApiRequest,
  postRawApiRequest
}
