<template>
  <v-subheader v-if="enabled">
    <span
      class="title-wrapper"
    >
      <span v-if="title">{{ title }}</span>
    </span>
  </v-subheader>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@include theme(v-subheader) using ($material) 
  white-space: nowrap
  position: relative

  .v-icon 
    color: map-deep-get($material, 'dividers')  

  .title-wrapper 
    width: 100%
    position: absolute
    left: 0
    border-bottom: 1px solid map-deep-get($material, 'dividers')
    line-height: 0.1em  

  .title-wrapper span 
    background: map-deep-get($material, 'background-bar')
    padding: 0 10px
    font-size: 0.7rem
    margin-left: 0px
    word-break: break-all  

.vertical-nav-menu-items 
  .v-subheader:not(:first-child) 
    margin-top: 18px  
</style>
